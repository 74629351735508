import { useKeenSlider } from "keen-slider/react";
import { RouterConfig } from "@shared/lib/routerConfig";
import { LeftIcon } from "@shared/ui/Icon/ui/Common/LeftIcon";
import { RightIcon } from "@shared/ui/Icon/ui/Common/RightIcon";
import { ALL_BRANDS_ITEM, COUNT_ITEMS_SLIDE } from "./constants";
import { CatalogBrandsProps } from "./types";
import classes from "./CatalogBrands.module.scss";

export const CatalogBrands = ({ brands }: CatalogBrandsProps) => {
    const [sliderRef, sliderInstance] = useKeenSlider<HTMLDivElement>({
        slides: {
            perView: 1,
            spacing: 10
        },
        loop: true
    });

    if (!brands || brands.length === 0) {
        return null;
    }

    const updatedBrands = [ALL_BRANDS_ITEM, ...brands];

    const slides = Array.from(
        { length: Math.ceil(updatedBrands.length / COUNT_ITEMS_SLIDE) },
        (_, i) =>
            updatedBrands.slice(i * COUNT_ITEMS_SLIDE, i * COUNT_ITEMS_SLIDE + COUNT_ITEMS_SLIDE)
    );

    return (
        <div className={classes.brands}>
            <div className={classes.brands__inner}>
                <div className={classes.brands__top}>
                    <h2>Каталог брендов</h2>
                    <div className={classes.brands__arrows}>
                        <div
                            className={classes.brands__arrow}
                            onClick={() => sliderInstance.current?.prev()}
                        >
                            <LeftIcon className={classes["brands__arrow--color"]} />
                        </div>
                        <div
                            className={classes.brands__arrow}
                            onClick={() => sliderInstance.current?.next()}
                        >
                            <RightIcon className={classes["brands__arrow--color"]} />
                        </div>
                    </div>
                </div>
                <div ref={sliderRef} className="keen-slider">
                    {slides.map((slide, index) => (
                        <div className="keen-slider__slide" key={index}>
                            <div className={classes.brands__list}>
                                {slide.map((brand) => (
                                    <a
                                        className={classes.brands__item}
                                        key={brand.id}
                                        href={
                                            brand.alias
                                                ? `${RouterConfig.CATALOG}/all-cities/${brand.alias}/all`
                                                : `${RouterConfig.CATALOG}`
                                        }
                                        aria-label={`Продажа автомобилей - ${brand.name}`}
                                    >
                                        {brand.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};