import { Organization, WithContext } from "schema-dts";
import { PROJECT_NAME } from "@shared/config/GlobalText";
import { configApi } from "@shared/lib/configApi";

export const organizationSchema: WithContext<Organization> = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: PROJECT_NAME,
    url: configApi.API_PUBLIC_DOMAIN,
    logo: `${configApi.API_PUBLIC_DOMAIN}/static/default-logo.png`,
    sameAs: [
        "https://vk.com/likvicom",
        "https://www.youtube.com/@Likvicom",
        "https://t.me/likvicom"
    ],
    contactPoint: {
        "@type": "ContactPoint",
        telephone: "+7-925-151-3156",
        contactType: "Customer service"
    }
};