import Image from "next/image";
import Link from "next/link";
import { BlogViewsIcon } from "@shared/ui/Icon/ui/Common/ViewsBlogIcon";
import { formatDate } from "../../../../utils/utils";
import { BlogCardProps } from "./types";
import classes from "./blog-card.module.scss";

const BlogCard = ({ title, date, slug, views, image }: BlogCardProps) => {
    return (
        <Link href={`/blog/${slug}`} className={classes.CardLink}>
            <article className={classes.BlogCard}>
                <div className={classes.Image}>
                    <Image
                        src={image}
                        alt={title}
                        fill={true}
                        sizes="(max-width: 550px) 100vw, (max-width: 900px) 50vw, 370px"
                    />
                </div>
                <div className={classes.Content}>
                    <div className={classes.Title}>{title}</div>
                    <div className={classes.Footer}>
                        <div className={classes.Date}>{formatDate(date.toString())}</div>
                        <div className={classes.Views}>
                            <div className={classes.Icon}>
                                <BlogViewsIcon />
                            </div>
                            {views}
                        </div>
                    </div>
                </div>
            </article>
        </Link>
    );
};

export default BlogCard;