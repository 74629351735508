import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import CatalogList from "@components/Features/catalog/catalog-list/catalog-list";
import {
    DEFAULT_PAGE_LIMIT,
    generateCatalogUri,
    generateFilterObject,
    MAIN_PAGE_ADBOCK_POSITIONS,
    PAGE_LIMIT_MAIN_SCREEN,
    TimeSort
} from "@components/Features/catalog/catalog-utils";
import BrandsList from "@components/Features/MainPage/BrandsList/BrandsList";
import MainFilter from "@components/Features/MainPage/MainFilter/MainFilter";
import MainPageTitle from "@components/Features/MainPage/MainPageTitle/MainPageTitle";
import { getCreatedTime, sortValueToQueryParam } from "@components/Screens/CatalogScreen/lib";
import {
    clearFilters,
    optimiseFilters,
    setMainScreenTimeSort,
    setMainScrenParamsSort
} from "@app/store/filtersSlice";
import { useGetOffersQuery } from "@app/store/offersApi";
import { AppState } from "@app/store/store";
import { CategoryFilter } from "@features/category-filter";
import Car from "@shared/lib/interfaces/car.interface";
import classes from "./MainScreen.module.scss";

const MainScreen = () => {
    const [page, setPage] = React.useState(1);
    const dispatch = useDispatch();
    const router = useRouter();

    const mainScreenTimeSort = useSelector((state: AppState) => state.filters.mainScreenTimeSort);
    const mainScreenAllParamsSort = useSelector(
        (state: AppState) => state.filters.mainScreenAllParamsSort
    );

    const [createdTimePrev, setCreatedTimePrev] = useState<string | null>("");
    useEffect(() => {
        setCreatedTimePrev(getCreatedTime(mainScreenTimeSort));
    }, [mainScreenTimeSort]);
    const createdParamSort = sortValueToQueryParam(mainScreenAllParamsSort);
    const limit = page === 1 ? DEFAULT_PAGE_LIMIT : PAGE_LIMIT_MAIN_SCREEN;

    const {
        brand: brandValue,
        model: modelValue,
        yearFrom: yearFromValue,
        yearTo: yearToValue,
        body: bodyValue,
        transmission: transmissionValue,
        engineType: engineTypeValue,
        drive: driveValue,
        engineVolumeFrom: engineVolumeFromValue,
        engineVolumeTo: engineVolumeToValue,
        wheel: wheelValue,
        priceFrom: priceFromValue,
        priceTo: priceToValue,
        mileageFrom: mileageFromValue,
        mileageTo: mileageToValue,
        horsePowerFrom: horsePowerFromValue,
        horsePowerTo: horsePowerToValue,
        generation: generationValue,
        ownersQntFrom: ownerQntFrom,
        ownersQntTo,
        sourceId,
        sellerType,
        city: citiesValue,
        country,
        hideNoImages
    } = useSelector((state: AppState) => state.filters);

    const [quantityFilters, setQuantityFilters] = React.useState<any>([]);
    useEffect(() => {
        const newFilters = generateFilterObject({
            brandValue: brandValue?.id,
            modelValue: modelValue?.id,
            generationValue: generationValue?.id,
            yearFromValue,
            yearToValue,
            engineVolumeFromValue,
            engineVolumeToValue,
            bodyValue,
            transmissionValue,
            engineTypeValue,
            driveValue,
            wheelValue,
            priceFromValue,
            priceToValue,
            mileageFromValue,
            mileageToValue,
            horsePowerFromValue,
            horsePowerToValue,
            ownerQntFrom,
            ownersQntTo,
            citiesValue,
            sourceId,
            sellerType,
            country,
            hideNoImages
        });

        setQuantityFilters(newFilters);
    }, [
        brandValue,
        modelValue,
        yearFromValue,
        yearToValue,
        bodyValue,
        transmissionValue,
        engineTypeValue,
        driveValue,
        wheelValue,
        priceFromValue,
        priceToValue,
        mileageFromValue,
        mileageToValue,
        horsePowerFromValue,
        horsePowerToValue,
        generationValue,
        ownerQntFrom,
        ownersQntTo,
        citiesValue,
        engineVolumeFromValue,
        engineVolumeToValue,
        sourceId,
        sellerType,
        country,
        hideNoImages
    ]);

    useEffect(() => {
        setPage(1);
        setDataMemory([]);
    }, [mainScreenTimeSort, mainScreenAllParamsSort]);

    const { data, error, isLoading, isFetching } = useGetOffersQuery(
        {
            page,
            sort: createdParamSort,
            created_at: createdTimePrev,
            hideNoImages: true,
            limit
        },
        {
            skip: createdTimePrev === "",
            refetchOnMountOrArgChange: true
        }
    );

    const [dataMemory, setDataMemory] = React.useState<Car[]>([]);

    useEffect(() => {
        if (data) {
            setDataMemory((prev) => [...prev, ...data.offers]);
        }
    }, [data]);

    const showMoreButton = dataMemory.length < data?.meta.total;

    const [previewQnt, setPreviewQnt] = useState<null | number>(0);
    const {
        data: updatePreviewQntData,
        isLoading: updatePreviewQntIsLoading,
        isFetching: updatePreviewQntIsFetching
    } = useGetOffersQuery({
        page: page,
        ...quantityFilters
    });

    useEffect(() => {
        setPreviewQnt(updatePreviewQntData?.meta?.total);
    }, [updatePreviewQntData]);

    const onFilterSubmit = () => {
        dispatch(optimiseFilters());
        const catalogUri = generateCatalogUri(
            citiesValue,
            brandValue?.alias,
            modelValue?.alias,
            generationValue?.generation,
            generationValue?.restyleNumber,
            {
                bodyType: bodyValue,
                priceFrom: priceFromValue,
                priceTo: priceToValue
            }
        );
        router.push(catalogUri);
    };

    return (
        <div className={classes["main-screen"]}>
            <div className={classes["main-screen__bg"]} />
            <div className={classes["main-screen__blackout"]} />

            <div className={classes["main-screen__content"]}>
                <MainPageTitle />
                <MainFilter
                    previewQnt={previewQnt}
                    updatePreviewQntIsLoading={updatePreviewQntIsLoading}
                    isPreviewQntFetching={updatePreviewQntIsFetching}
                    onFilterSubmit={onFilterSubmit}
                    resetFilters={() => {
                        dispatch(clearFilters());
                    }}
                />
                <BrandsList />
                <CategoryFilter />
                <div className={classes["main-screen__catalog"]}>
                    {error && (
                        <div className={classes["main-screen__catalog-error"]}>
                            Ошибка загрузки данных
                        </div>
                    )}
                    {isLoading && <div className="custom-loader"></div>}

                    {data?.offers && (
                        <CatalogList
                            title="Последние объявления"
                            headerTag="h2"
                            defaultTimeSort={TimeSort.Week}
                            onTimeSortChanged={(sort) => {
                                dispatch(setMainScreenTimeSort(sort));
                            }}
                            onParamsSortChanged={(sort) => {
                                dispatch(setMainScrenParamsSort(sort));
                            }}
                            allParamsSort={mainScreenAllParamsSort}
                            timeSort={mainScreenTimeSort}
                            isDataLoading={isFetching}
                            data={dataMemory}
                            showLoadMore={showMoreButton}
                            loadMore={async () => {
                                setPage(page + 1);
                            }}
                            onFilterChanged={() => {
                                setPage(1);
                                setDataMemory([]);
                            }}
                            showResetFiltersWhenNoData={false}
                            banners={data?.banners}
                            page={page}
                            adBlockPositions={MAIN_PAGE_ADBOCK_POSITIONS}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainScreen;