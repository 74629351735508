import { AsterKzIcon } from "@shared/ui/Icon/ui/Boards/AsterKzIcon";
import { AutoRuIcon } from "@shared/ui/Icon/ui/Boards/AutoRuIcon";
import { AvByIcon } from "@shared/ui/Icon/ui/Boards/AvByIcon";
import { AvitoIcon } from "@shared/ui/Icon/ui/Boards/AvitoIcon";
import { DromIcon } from "@shared/ui/Icon/ui/Boards/DromIcon";
import { KolesaKzIcon } from "@shared/ui/Icon/ui/Boards/KolesaKzIcon";
import { MigTorgBlackIcon } from "@shared/ui/Icon/ui/Boards/MigTorgBlackIcon";
import { MigTorgWhiteIcon } from "@shared/ui/Icon/ui/Boards/MigTorgWhiteIcon";
import { MobileDeIcon } from "@shared/ui/Icon/ui/Boards/MobileDeIcon";
import { MyCarIcon } from "@shared/ui/Icon/ui/Boards/MyCarIcon";
import { UremontIcon } from "@shared/ui/Icon/ui/Boards/UremontIcon";
import { PlatformData } from "./types";

export const iconData: PlatformData[] = [
    { Icon: DromIcon, label: "Объявления с drom.ru", platformId: 1 },
    { Icon: AutoRuIcon, label: "Объявления с auto.ru", platformId: 3 },
    { Icon: AvitoIcon, label: "Объявления с avito.ru", platformId: 2 },
    { Icon: AsterKzIcon, label: "Объявления с aster.kz", platformId: 6 },
    { Icon: MyCarIcon, label: "Объявления с mycar.kz", platformId: 7 },
    {
        Icon: KolesaKzIcon,
        label: "Объявления с kolesa.kz",
        platformId: 8
    },
    {
        Icon: MobileDeIcon,
        label: "Объявления с mobile.de",
        platformId: 9
    },
    { Icon: AvByIcon, label: "Объявления с av.by", platformId: 10 },
    { Icon: UremontIcon, label: "Объявления с uremont.com", platformId: 11 },
    { Icon: MigTorgWhiteIcon, label: "Объявления с migtorg.com", platformId: 12 }
];