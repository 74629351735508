import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const UremontIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} viewBox="0 0 194 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5317_35474)">
            <path
                d="M28 14.3172C28.0012 11.6673 27.2826 9.069 25.9242 6.81196C24.5657 4.55492 22.6209 2.72776 20.3063 1.53408C17.9918 0.340402 15.3984 -0.172912 12.8153 0.0513393C10.2321 0.275591 7.76057 1.2286 5.6761 2.80417C3.59164 4.37974 1.9761 6.51599 1.0095 8.97487C0.0429111 11.4337 -0.236767 14.1187 0.201635 16.7305C0.640038 19.3423 1.7793 21.7783 3.49247 23.7672C5.20564 25.7561 7.42544 27.2197 9.90449 27.9949V22.5735C8.3887 21.7953 7.11342 20.6032 6.22011 19.1293C5.3268 17.6554 4.85039 15.9573 4.84372 14.2235C4.83705 12.4897 5.30039 10.7879 6.18233 9.30687C7.06428 7.82584 8.33035 6.62349 9.84012 5.83319V14.4387L13.9802 16.828L18.1203 14.4387V5.83319C19.646 6.61241 20.9292 7.81062 21.826 9.29349C22.7229 10.7764 23.198 12.4853 23.198 14.2286C23.198 15.972 22.7229 17.6809 21.826 19.1638C20.9292 20.6467 19.646 21.8449 18.1203 22.6241V28C20.9817 27.0985 23.4851 25.2833 25.2632 22.8207C27.0413 20.3581 28.0005 17.3779 28 14.3172Z"
                fill="#227DF5"
            />
            <path
                d="M34.1191 15.315V6.58545H38.4593V15.1465C38.4593 16.5621 39.3662 17.708 41.1153 17.708C42.8644 17.708 43.7389 16.5621 43.7389 15.1465V6.58545H48.0792V15.2813C48.0792 18.9551 45.8766 21.6515 41.0829 21.6515C36.354 21.6852 34.1191 18.9888 34.1191 15.315Z"
                fill="white"
            />
            <path
                d="M58.1841 21.4156L56.2083 16.7643H54.6536V21.4156H50.3457V6.58545H57.9574C61.2935 6.58545 63.1074 8.91108 63.1074 11.6749C63.1074 14.2364 61.6822 15.5846 60.5486 16.1576L63.1398 21.3819L58.1841 21.4156ZM58.7023 11.6749C58.7023 10.866 58.0221 10.5289 57.2772 10.5289H54.6536V12.8545H57.2772C57.9898 12.8545 58.7023 12.4838 58.7023 11.6749Z"
                fill="white"
            />
            <path
                d="M64.8242 21.4158V6.61938H75.6101V10.4954H69.1321V11.9785H75.4805V15.8882H69.1321V17.506H75.6101V21.4158H64.8242Z"
                fill="white"
            />
            <path
                d="M89.9603 21.4158V12.5177L86.7536 21.4158H84.8426L81.6684 12.5177V21.4158H77.3281V6.61938H83.3203L85.8143 13.6974L88.3084 6.61938H94.2681V21.4158H89.9603Z"
                fill="white"
            />
            <path
                d="M189.693 21.4158V12.5177L186.487 21.4158H184.576L181.369 12.5177V21.4158H177.061V6.61938H183.021L185.515 13.6974L188.041 6.61938H194.001V21.4158H189.693Z"
                fill="white"
            />
            <path
                d="M95.7578 14.0001C95.7578 9.44997 99.1264 6.34912 103.5 6.34912C107.872 6.34912 111.241 9.44997 111.241 14.0001C111.241 18.5503 107.872 21.6511 103.5 21.6511C99.1264 21.6848 95.7578 18.5503 95.7578 14.0001ZM106.868 14.0001C106.868 11.9441 105.54 10.2926 103.467 10.2926C101.394 10.2926 100.066 11.9441 100.066 14.0001C100.066 16.0561 101.394 17.7077 103.467 17.7077C105.54 17.7077 106.868 16.0898 106.868 14.0001Z"
                fill="white"
            />
            <path
                d="M159.893 14.0001C159.893 9.44997 163.261 6.34912 167.634 6.34912C172.006 6.34912 175.375 9.44997 175.375 14.0001C175.375 18.5503 172.006 21.6511 167.634 21.6511C163.261 21.6848 159.893 18.5503 159.893 14.0001ZM171.002 14.0001C171.002 11.9441 169.674 10.2926 167.601 10.2926C165.528 10.2926 164.2 11.9441 164.2 14.0001C164.2 16.0561 165.528 17.7077 167.601 17.7077C169.674 17.7077 171.002 16.0898 171.002 14.0001Z"
                fill="white"
            />
            <path
                d="M122.286 21.4158L117.007 13.8996V21.4158H112.699V6.61938H117.136L122.092 13.63V6.61938H126.432V21.4158H122.286Z"
                fill="white"
            />
            <path
                d="M131.681 21.4158V10.4954H127.891V6.61938H139.681V10.4954H135.924V21.4158H131.681Z"
                fill="white"
            />
            <path
                d="M142.079 21.4158C140.815 21.4158 139.682 20.6405 139.682 18.989C139.682 17.3375 140.815 16.5623 142.079 16.5623C143.374 16.5623 144.475 17.3375 144.475 18.989C144.508 20.6405 143.374 21.4158 142.079 21.4158Z"
                fill="white"
            />
            <path
                d="M156.329 16.2583C155.746 17.1347 154.807 17.7077 153.576 17.7077C151.503 17.7077 150.175 16.0561 150.175 14.0001C150.175 11.9441 151.503 10.2926 153.576 10.2926C154.807 10.2926 155.746 10.8656 156.329 11.7756L159.277 8.70846C157.884 7.22545 155.876 6.34912 153.544 6.34912C149.171 6.34912 145.803 9.44997 145.803 14.0001C145.803 18.5503 149.171 21.6511 153.544 21.6511C155.844 21.6511 157.852 20.7748 159.245 19.3255L156.329 16.2583Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5317_35474">
                <rect width="194" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
);